<template>
  <v-dialog v-model="value" fullscreen>
    <v-card :loading="loadingMain" :disabled="loadingMain">
      <template slot="progress">
        <v-progress-linear color="primary" indeterminate></v-progress-linear>
      </template>

      <BarTitle @close="close()" title="MEDIÇÃO" />

      <!-- <v-card-actions>
        <v-row> -->
      <!-- <v-col class="text-left">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon x-large color="primary" outlined v-bind="attrs" v-on="on"><v-icon> mdi-menu
                  </v-icon></v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-list-item-title @click="exportPdf()"><v-icon>mdi-file-pdf-box</v-icon> Exportar
                    Pdf</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col> -->
      <!-- <v-col class="text-right">
            <v-btn v-if="!approved" :loading="loadingApproved" :disabled="loadingApproved" @click="approvedContract()"
              x-large color="success">aprovar</v-btn>
          </v-col> -->
      <!-- </v-row>
      </v-card-actions> -->
      <v-card-actions>
        <v-row>
          <!-- <v-col class="text-left">
            <v-btn v-if="!approved" :loading="loadingApproved" :disabled="loadingApproved" @click="approvedContract()"
              x-large color="success">aprovar</v-btn>
          </v-col> -->
          <v-col class="text-right">
            <v-btn v-if="!approved" text x-large>fechar</v-btn>
            <v-btn
              v-if="!approved"
              :loading="loadingSave"
              :disabled="loadingSave"
              @click="save('close')"
              x-large
              color="primary"
              >SALVAR</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
      <v-divider class="mb-4"></v-divider>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="4">
            <v-select
              hide-details
              class="mr-2"
              v-model="contractor"
              :items="contractors"
              item-value="id"
              item-text="fantasy_name"
              outlined
              label="Empreiteiro"
            ></v-select>
          </v-col>
          <!-- <v-col><b>SUB-EMPRETEIRO :</b> {{ contractor.fantasy_name }}</v-col> -->
          <v-col cols="2">
            <v-text-field
              hide-details
              class="mr-2"
              v-model="meteringDetached.date_start"
              type="date"
              outlined
              label="Data Inicio"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-text-field
              hide-details
              class="mr-2"
              v-model="meteringDetached.date_end"
              type="date"
              outlined
              label="Data Fim"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              hide-details
              v-model="meteringDetached.metering_n"
              outlined
              label="Medição Nº"
            ></v-text-field>
          </v-col>
          <!-- <v-col><b>PERÍODO :</b>
            {{
              formatDate(contract.date_start) + " - " + formatDate(contract.date_end)
            }}
            </v-col> -->
        </v-row>
        <!-- <v-row> -->

        <!-- <v-col>
            <div style="white-space: nowrap">
              <div v-if="approved" class=""><b>MEDICÃO N°:</b> {{ contract.metering_n }}</div>
              <v-edit-dialog v-if="!approved" :return-value.sync="contract.metering_n" large cancel-text="fechar"
                save-text="salvar">
                <div class="editable"><b>MEDICÃO N°:</b> {{ contract.metering_n }}</div>
                <template v-slot:input>
                  <v-text-field v-model="contract.metering_n"></v-text-field>
                </template>
              </v-edit-dialog>
            </div>
          </v-col> -->
        <!-- <v-col><b>DATA:</b> {{ formatDateTime(contract.date_approved) }} <b>Por:</b> {{ userApproved
            }}</v-col> -->
        <!-- </v-row> -->
        <v-row>
          <v-col>
            <v-card outlined>
              <v-row>
                <v-col class="text-right">
                  <v-btn
                    @click="dialogAddService = true"
                    class="mt-2 mr-2"
                    rounded
                    color="primary"
                    ><v-icon>mdi-plus</v-icon>SERVIÇO</v-btn
                  >
                </v-col>
              </v-row>

              <v-data-table
                dense
                :headers="headers"
                :items="getServices"
                :items-per-page="5"
                class="mt-4 elevation-0"
              >
                <!-- ITEM  -->
                <template v-slot:item.item="{ item }">
                  {{ getServices.indexOf(item) }}
                </template>

                <!-- CC  -->
                <template v-slot:item.cc="{ item }">
                  {{ item.stage.code + " - " + item.stage.name }}
                </template>

                <!-- SERVIÇO  -->
                <template v-slot:item.service="{ item }">
                  {{ item.composition.name }}
                </template>

                <!-- UNIDADE -->
                <template v-slot:item.unity="{ item }">
                  {{ item.composition.unity }}
                </template>

                <!-- QUANTIDADE -->
                <template v-slot:item.quantity="{ item }">
                  {{ formatMoney(item.quantity) }}
                </template>

                <!-- PRECO UNITARIO -->
                <template v-slot:item.unit_price="{ item }">
                  {{ formatMoney(item.composition.total_with_tax) }}
                </template>

                <template v-slot:item.sub_total="{ item }">
                  {{ formatMoney(item.composition.total_with_tax * item.quantity) }}
                </template>
                <template v-slot:no-data> Nenhum item na lista. </template>

                <template v-slot:item.actions="{ item }">
                  <v-btn color="error" icon @click="sendRemoveService(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <template v-slot:foot>
                  <tr>
                    <td
                      style="background: lightgray; height: 35px"
                      class="text-right"
                      colspan="6"
                    >
                      SUB-TOTAL:
                    </td>
                    <td
                      style="
                        background: lightgray;
                        height: 35px;
                        padding-left: 16px;
                        padding-right: 16px;
                      "
                      class="text-right"
                    >
                      <b>{{ formatMoney(subtotalContractorItems()) }}</b>
                    </td>
                    <td style="background: lightgray; height: 35px"></td>
                  </tr>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="cols-4">
            <v-textarea
              v-model="meteringDetached.obs"
              rows="11"
              label="Observações"
              class=""
              outlined
            ></v-textarea>
          </v-col>
          <v-col>
            <v-card outlined style="font-size: 16px">
              <v-row
                style="font-size: 18px; background: #1976d2; color: white; height: 30px"
                no-gutters
              >
                <v-col><b class="ml-2">SUBTOTAL 1</b></v-col>
                <v-col class="text-right" style="font-size: 14px">QUANT</v-col>
                <v-col class="text-right" style="font-size: 14px">P. UNIT</v-col>
                <v-col class="text-right"
                  ><b class="mr-2">{{ formatMoney(subtotalContractorItems()) }}</b></v-col
                >
              </v-row>
              <v-row
                v-for="item in metering_detached_expenses"
                :key="item.description"
                style="background: lightgray; height: 25px"
                no-gutters
              >
                <v-col>
                  <div v-if="approved" class="ml-2">{{ item.description }}</div>
                  <v-edit-dialog
                    v-if="!approved"
                    :return-value.sync="item.description"
                    large
                    cancel-text="fechar"
                    save-text="salvar"
                  >
                    <div class="editable ml-2">{{ item.description }}</div>
                    <template v-slot:input>
                      <v-text-field v-model="item.description"></v-text-field>
                    </template>
                  </v-edit-dialog>
                </v-col>
                <v-col class="text-right">
                  <div v-if="approved" class="">{{ formatMoney(item.base) }}</div>
                  <v-edit-dialog
                    v-if="!approved"
                    @save="calcTotal()"
                    :return-value.sync="item.base"
                    large
                    cancel-text="fechar"
                    save-text="salvar"
                  >
                    <div class="editable">{{ formatMoney(item.base) }}</div>
                    <template v-slot:input>
                      <vuetify-money
                        v-model="item.base"
                        :clearable="true"
                        valueWhenIsEmpty="0"
                        :options="{
                          locale: 'pt-BR',
                          length: 11,
                          precision: 2,
                        }"
                      />
                    </template>
                  </v-edit-dialog>
                </v-col>
                <v-col class="text-right">
                  <div v-if="approved" class="">{{ formatMoney(item.fator) }}</div>
                  <v-edit-dialog
                    v-if="!approved"
                    @save="calcTotal()"
                    :return-value.sync="item.fator"
                    large
                    cancel-text="fechar"
                    save-text="salvar"
                  >
                    <div class="editable">{{ formatMoney(item.fator) }}</div>
                    <template v-slot:input>
                      <vuetify-money
                        v-model="item.fator"
                        :clearable="true"
                        valueWhenIsEmpty="0"
                        :options="{
                          locale: 'pt-BR',
                          length: 11,
                          precision: 2,
                        }"
                      />
                    </template>
                  </v-edit-dialog>
                </v-col>
                <v-col class="text-right">
                  <b class="mr-2">{{ formatMoney(item.sub_total) }}</b>
                </v-col>
              </v-row>

              <v-row
                style="font-size: 18px; background: #1976d2; color: white; height: 30px"
                no-gutters
              >
                <v-col><b class="ml-2">SUBTOTAL 2</b></v-col>
                <v-col class="text-right" style="font-size: 14px">BASE</v-col>
                <v-col class="text-right" style="font-size: 14px">ALÍQUOTA</v-col>
                <v-col class="text-right"
                  ><b class="mr-2">{{ formatMoney(subTotal2) }}</b></v-col
                >
              </v-row>
              <v-row
                v-for="(item, index) in metering_detached_taxes"
                :key="index"
                style="background: lightgray; height: 25px"
                no-gutters
              >
                <v-col>
                  <div v-if="approved" class="ml-2">{{ item.description }}</div>
                  <v-edit-dialog
                    v-if="!approved"
                    :return-value.sync="item.description"
                    large
                    cancel-text="fechar"
                    save-text="salvar"
                  >
                    <div class="editable ml-2">{{ item.description }}</div>
                    <template v-slot:input>
                      <v-text-field v-model="item.description"></v-text-field>
                    </template>
                  </v-edit-dialog>
                </v-col>
                <v-col class="text-right">
                  <div v-if="approved" class="">{{ formatMoney(item.base) }}</div>
                  <v-edit-dialog
                    v-if="!approved"
                    @save="calcTotal()"
                    :return-value.sync="item.base"
                    large
                    cancel-text="fechar"
                    save-text="salvar"
                  >
                    <div class="editable">{{ formatMoney(item.base) }}</div>
                    <template v-slot:input>
                      <vuetify-money
                        v-model="item.base"
                        :clearable="true"
                        valueWhenIsEmpty="0"
                        :options="{
                          locale: 'pt-BR',
                          length: 11,
                          precision: 2,
                        }"
                      />
                    </template>
                  </v-edit-dialog>
                </v-col>
                <v-col class="text-right">
                  <div v-if="approved" class="">{{ formatMoney(item.fator) }} %</div>
                  <v-edit-dialog
                    v-if="!approved"
                    @save="calcTotal()"
                    :return-value.sync="item.fator"
                    large
                    cancel-text="fechar"
                    save-text="salvar"
                  >
                    <div class="editable">{{ formatMoney(item.fator) }} %</div>
                    <template v-slot:input>
                      <vuetify-money
                        v-model="item.fator"
                        :clearable="true"
                        valueWhenIsEmpty="0"
                        :options="{
                          locale: 'pt-BR',
                          length: 11,
                          precision: 2,
                        }"
                      />
                    </template>
                  </v-edit-dialog>
                </v-col>
                <v-col class="text-right">
                  <b class="mr-2">{{ formatMoney(item.sub_total) }}</b>
                </v-col>
              </v-row>
              <v-row
                no-gutters
                style="
                  font-size: 18px;
                  font-weight: bold;
                  background: #1976d2;
                  color: white;
                  height: 30px;
                "
              >
                <v-col class="ml-2">TOTAL:</v-col>
                <v-col class="text-right mr-2"> {{ formatMoney(total) }}</v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <!-- <v-divider></v-divider> -->
    </v-card>
    <DialogAddService v-model="dialogAddService"></DialogAddService>
  </v-dialog>
</template>

<script>
import BarTitle from "@/pages/Components/BarTitle.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import units from "@/plugins/units.js";
import DialogAddService from "./DialogAddService.vue";

export default {
  props: {
    value: {
      type: Boolean,
    },
    metering_detached: {
      type: Object,
    },
  },

  components: {
    BarTitle,
    DialogAddService,
  },

  data: () => ({
    dialogAddService: false,

    contractors: [],
    contractor: null,
    approved: false,
    userApproved: "",
    total: 0,
    subTotal2: 0,
    subTotal1: 0,
    itemsUnits: units.units,
    loadingMain: false,
    loadingSave: false,
    loadingApproved: false,
    meteringDetached: {},
    contractSummaryItems: [],
    metering_detached_item_deleted: [],

    metering_detached_expenses: [],
    metering_detached_taxes: [],

    headers: [
      {
        text: "ITEM",
        value: "item",
      },
      {
        text: "CC",
        value: "cc",
      },
      {
        text: "SERVIÇO",
        value: "service",
      },
      {
        text: "UND",
        value: "unity",
      },
      {
        text: "QUANTIDADE",
        value: "quantity",
        align: "right",
      },
      {
        text: "P. UNIT",
        value: "unit_price",
        align: "right",
      },
      {
        text: "SUB-TOTAL",
        value: "sub_total",
        align: "right",
      },
      {
        text: "",
        value: "actions",
        align: "right",
      },
    ],
    headers_subtotal1: [
      {
        text: "SUB-TOTAL 1",
        value: "",
      },
      {
        text: "",
        value: "",
      },
      {
        text: "",
        value: "",
      },
      {
        text: "",
        value: "",
      },
    ],
  }),

  created() {
    //carregar os empreiteiros
    this.getContractors().then((response) => {
      this.contractors = response.data;
    });
  },
  watch: {
    value(val) {
      if (val) {
        this.initialize();
      }
    },
    dialogAddService() {
      this.calcTotal();
    },
  },
  computed: {
    ...mapGetters("MeteringDetached", ["getServices"]),
  },

  methods: {
    ...mapMutations("MeteringDetached", ["removeService", "setService"]),
    ...mapActions("MeteringDetached", [
      "getContractSummary",
      "updateCreateContractorSummary",
      "approvedServices",
      "getExportPdf",
      "getContractors",
      "store",
      "update",
    ]),
    initialize() {
      //define items depesas padrao
      this.metering_detached_expenses = [
        {
          description: "ALMOÇO",
          base: 0,
          fator: 0,
          sub_total: 0,
        },
        {
          description: "CAFÉ",
          base: 0,
          fator: 0,
          sub_total: 0,
        },
        {
          description: "EPI",
          base: 0,
          fator: 0,
          sub_total: 0,
        },
        {
          description: "OUTROS",
          base: 0,
          fator: 0,
          sub_total: 0,
        },
      ];

      // define items de impostos padrao
      this.metering_detached_taxes = [
        {
          description: "ISS (%)",
          base: 0,
          fator: 0,
          sub_total: 0,
        },
        {
          description: "INSS (%)",
          base: 0,
          fator: 0,
          sub_total: 0,
        },
        {
          description: "PIS (%)",
          base: 0,
          fator: 0,
          sub_total: 0,
        },
        {
          description: "COFINS (%)",
          base: 0,
          fator: 0,
          sub_total: 0,
        },
        {
          description: "CSLL (%)",
          base: 0,
          fator: 0,
          sub_total: 0,
        },
        {
          description: "IR (%)",
          base: 0,
          fator: 0,
          sub_total: 0,
        },
      ];
      //limpa o array de item deletados
      this.metering_contract_item_deleted = [];

      if (this.metering_detached.id) {
        this.meteringDetached = this.metering_detached;
        this.contractor = this.meteringDetached.contractor.id;
        this.metering_detached_expenses = this.meteringDetached.metering_detached_expenses;
        this.metering_detached_taxes = this.meteringDetached.metering_detached_taxes;
        this.setService(this.meteringDetached.metering_detached_items);
      } else {
        this.meteringDetached = [];
        this.contractor = null;
        this.setService([]);
      }

      this.calcTotal();
    },
    close() {
      this.$emit("input", false);
    },

    subtotalContractorItems() {
      const result = this.getServices.reduce((acc, item) => {
        return acc + parseFloat(item.quantity * item.composition.total_with_tax);
      }, 0);

      return result;
    },

    setPeriod() {
      if (this.contract) {
        this.dateStart = this.contract.items[0].date_start;
        this.dateEnd = this.contract.items[0].date_end;
      }
    },
    exportPdf() {
      this.getExportPdf(this.contractor)
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", "export_summary_contractor.pdf");
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .finally(() => {});

      this.$toast.success("Exportando PDF");
    },
    /**
     * SALVAR O CONTRATO E SEUS ITEMS
     */
    save($param) {
      // VALIDACAO

      if (this.contractor == null || this.contractor == "") {
        this.$toast.error("Empreiteiro é um campo obrigatório ");
        return false;
      }

      if (
        this.meteringDetached.date_start == null ||
        this.meteringDetached.date_start == ""
      ) {
        this.$toast.error("Data inicio é um campo obrigatório ");
        return false;
      }

      if (
        this.meteringDetached.date_end == null ||
        this.meteringDetached.date_end == ""
      ) {
        this.$toast.error("Data Fim é um campo obrigatório ");
        return false;
      }

      if (
        this.meteringDetached.metering_n == null ||
        this.meteringDetached.metering_n == ""
      ) {
        this.$toast.error("Medição é um campo obrigatório ");
        return false;
      }

      this.loadingSave = true;
      const data = {
        metering_detached_id: this.metering_detached.id,
        contractor_id: this.contractor,
        metering_detached_expenses: this.metering_detached_expenses,
        metering_detached_taxes: this.metering_detached_taxes,
        metering_detached_item_deleted: this.metering_detached_item_deleted,
        metering_detached_items: this.getServices,

        obs: this.meteringDetached.obs,
        date_start: this.meteringDetached.date_start,
        date_end: this.meteringDetached.date_end,
        metering_n: this.meteringDetached.metering_n,
      };

      if (this.metering_detached.id) {
        //update

        this.update(data)
          .then(() => {
            this.$toast.success("Salvo com sucesso.");
            this.$emit("executed");
            // switch ($param) {
            //   case "close":
            //     this.$emit("input", false);
            //     break;
            //   case "approved":
            //     this.sendApproved();
            //     break;
            // }
          })
          .finally(() => {
            this.loadingSave = false;
          });
      } else {
        // store

        this.store(data)
          .then(() => {
            this.$toast.success("Salvo com sucesso.");
            this.$emit("executed");
            switch ($param) {
              case "close":
                this.$emit("input", false);
                break;
              case "approved":
                this.sendApproved();
                break;
            }
          })
          .finally(() => {
            this.loadingSave = false;
          });
      }

      // this.contract.local_metering_id = this.metering.id
      // this.contract.metering_contract_item = this.metering_contract_item
      // this.contract.metering_contract_expenses = this.metering_contract_expenses
      // this.contract.metering_contract_taxes = this.metering_contract_taxes

      // const data = {
      //     contract: this.contract,
      //     metering_contract_item_deleted: this.metering_contract_item_deleted,
      // }

      // this.updateCreateMeteringContract(data).then(() => {
      //         this.$toast.success('Salvo com sucesso')
      //         this.close()
      //     })
      //     .finally(() => {
      //         this.loadingSave = false
      //     })
    },
    /**
     * APROVAR SERVIÇOS DO EMREITEIRO EM UM PERIODO ESPECIFICO
     */

    approvedContract() {
      this.save("approved");
    },
    sendApproved() {
      this.loadingApproved = true;
      this.approvedServices(this.contractor.id)
        .then(() => {
          //fechar o modal de controle de medicoes
          this.$emit("refreshTable");
          this.close();
          this.$toast.success("Controle de medição aprovado. ");
        })
        .finally(() => {
          this.loadingApproved = false;
        });
    },
    // /**
    //  * ADICIONA NOVO SERVIÇO
    //  */
    // addService() {
    //   const service = {
    //     order: 0,
    //     contract_code: 0,
    //     description: "Serviço",
    //     unit: "unidade",
    //     quantity: 0,
    //     unit_price: 0,
    //     sub_total: 0,
    //   };

    //   this.metering_contract_item.push(Object.assign({}, service));
    // },

    /**
     * REMOVE UM ITEM DE SERVICOS
     */
    sendRemoveService(service) {
      this.metering_detached_item_deleted.push(Object.assign({}, service));
      this.removeService(service);
    },

    /**
     *CALCULA OS SUB-TOTAIS DE CADA SERVIÇO
     */
    calcTotal() {
      //sub total de cada linha do SUBTOTAL1
      this.metering_detached_expenses.forEach((item) => {
        item.sub_total = parseFloat(item.base) * parseFloat(item.fator);
      });

      //sub total de cada linha do SUBTOTAL2
      this.metering_detached_taxes.forEach((item) => {
        item.sub_total = parseFloat(item.base) * (parseFloat(item.fator) / 100);
      });

      //somatorio de subtotais do SUBTOTAL1
      let sumSubtotal1 = this.metering_detached_expenses.reduce((acc, item) => {
        return acc + parseFloat(item.sub_total);
      }, 0);

      this.subTotal2 = this.subtotalContractorItems() - parseFloat(sumSubtotal1);

      //somatorio de subtotais do SUBTOTAL2
      let sumSubtotal2 = this.metering_detached_taxes.reduce((acc, item) => {
        return acc + parseFloat(item.sub_total);
      }, 0);

      //total
      this.total = parseFloat(this.subTotal2) - parseFloat(sumSubtotal2);
    },

    /**
     * FORMATA FLOAT PARA MONETARIO
     * @param number
     */
    formatMoney(number) {
      if (isNaN(number)) {
        return 0;
      }
      number = parseFloat(number);

      return number.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    formatDate(date) {
      if (date) {
        let date_split = date.split("-");
        return date_split[2] + "/" + date_split[1] + "/" + date_split[0];
      }
      return "";
    },
    formatDateTime(date) {
      if (date) {
        date = new Date(date);
        let dateTime =
          date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear() + " ";
        dateTime += date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
        return dateTime;
      }

      return "";
    },
  },
};
</script>

<style scoped>
.editable {
  color: #1976d2;
  font-weight: bold;
}
</style>
